<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.POST") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.news"
                v-model="formModel.status"
                :items="statuses.news.news"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref="date_picker_created_at"
                v-model="date_picker_created_at"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="created_at_date"
                    :label="$t('FORMS.date_of_created_at')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="created_at_date"
                  no-title
                  scrollable
                  locale="hu-HU"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="date_picker_created_at = false"
                  >
                    {{ $t("FORMS.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.date_picker_created_at.save(created_at_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref="time_picker_created_at"
                v-model="time_picker_created_at"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="created_at_time"
                    :label="$t('FORMS.time_of_created_at')"
                    prepend-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_picker_created_at"
                  v-model="created_at_time"
                  format="24hr"
                  full-width
                  @click:minute="
                    $refs.time_picker_created_at.save(created_at_time)
                  "
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :rules="nameRules"
                class="mt-4"
                :label="$t('FORM_INPUT_NAMES.title')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
                @change="handleChangeName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <!--:rules="nameRules"-->
              <v-text-field
                v-if="
                  formModel.id && formModel.translations[selectedLocale.lang]
                "
                class="mt-4"
                v-model="formModel.translations[selectedLocale.lang].slug"
                :label="$t('FORMS.slug')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.slug']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.slug'] = ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="formModel.categories"
                :items="newsCategoryCollection"
                attach
                chips
                deletable-chips="true"
                item-text="name"
                item-value="id"
                :label="$t('MENU.NEWS_CATEGORIES')"
                multiple
                @change="handleSelectCategory"
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.categories)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4" v-if="activeSiteId == 2">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{ type: 'text', label: 'FORM_INPUT_NAMES.author' }"
                customFieldName="author"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .author
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" sm="12" md="12" v-if="activeSiteId == 1">
              {{ $t("FORMS.intro") }}
              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].intro"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.intro']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.intro'] =
                    ''
                "
                :config="editorConfigIntro"
              ></ckeditor>
              <small
                v-if="
                  messages['translations.' + selectedLocale.lang + '.intro']
                "
                style="color: red"
              >
                {{ messages["translations." + selectedLocale.lang + ".intro"] }}
              </small>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="12" sm="12" md="12">
              {{ $t("FORMS.text") }}
              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].text"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.text']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.text'] = ''
                "
                :config="editorConfig2"
                @blur="handleGenerateSEOText"
              ></ckeditor>
              <small
                v-if="messages['translations.' + selectedLocale.lang + '.text']"
                style="color: red"
              >
                {{ messages["translations." + selectedLocale.lang + ".text"] }}
              </small>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-combobox
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].tags"
                :items="computedTagCollection"
                :label="$t('FORM_INPUT_NAMES.tags')"
                multiple
              >
                <template #selection="{ item }">
                  <v-chip
                    close
                    color="bg-gray-100"
                    @click:close="
                      deleteChipComboBox(
                        item,
                        formModel.translations[selectedLocale.lang].tags
                      )
                    "
                    >{{ item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12">
              <v-autocomplete
                :items="computedRelatedNewsCollection"
                v-model="formModel.related_news"
                :label="$t('FORM_INPUT_NAMES.related_news')"
                item-text="label"
                item-value="id"
                multiple
                @input="setLimitRelatedNews"
              >
                <template #selection="{ item }">
                  <v-chip
                    close
                    color="bg-gray-100"
                    @click:close="
                      deleteChipAutocomplete(item, formModel.related_news)
                    "
                    >{{ item.label }}</v-chip
                  >
                </template>
              </v-autocomplete>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'text',
                  label: 'FORMS.seo_title',
                  charLimit: 60,
                }"
                customFieldName="seoTitle"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoTitle
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: 'FORMS.seo_description',
                  charLimit: 160,
                }"
                customFieldName="seoDescription"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoDescription
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="2"
              md="2"
              class="mt-2"
              v-if="canImportMedia('featured_image')"
            >
              <v-btn
                color="primary"
                outlined
                @click="handleImportMedia('featured_image')"
                >{{ $t("FORM_INPUT_NAMES.import_images") }}</v-btn
              >
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="featured_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .featured_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.featured_image',
                  resolution: '1128x677',
                  selectButtonTextNotTransalated: '',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="card_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .card_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.card_image',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="2"
              md="2"
              class="mt-2"
              v-if="canImportMedia('gallery')"
            >
              <v-btn
                color="primary"
                outlined
                @click="handleImportMedia('gallery')"
                >{{ $t("FORM_INPUT_NAMES.import_images") }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="gallery"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .gallery || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: -1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.description',
                      type: 'text',
                    },
                  ],
                  selectButtonText: $t('FORM_INPUT_NAMES.gallery'),
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <!-- <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{ type: 'text', label: 'FORMS.seo_title' }"
                customFieldName="seoTitle"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoTitle
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: 'FORMS.seo_description',
                }"
                customFieldName="seoDescription"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoDescription
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row> -->
          <v-row>
            <!-- <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.og_site_name',
                }"
                customFieldName="og_site_name"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_site_name || ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col> -->
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.og_title',
                }"
                customFieldName="og_title"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_title || ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="og_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  selectButtonText: 'FORM_INPUT_NAMES.og_image',
                  initialColumnClass:
                    'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="formModel && formModel.custom_fields"
                :config="{
                  type: 'multi_select',
                  multiple: false,
                  label: 'FORM_INPUT_NAMES.og_type',

                  items: og_types,
                }"
                customFieldName="og_type"
                :value="formModel.custom_fields.og_type || 'article'"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: 'FORM_INPUT_NAMES.og_description',
                }"
                customFieldName="og_description"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_description || ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->
        <!--<PreviewURLComponent
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          slug="slug"
          :lang="selectedLocale.lang"
          type="news"
          :formModel="formModel"
          @handlePreview="handleSaveModalForm"
        ></PreviewURLComponent>-->
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm(null)"
        >
          {{ $t("FORMS.save") }}
        </v-btn>

        <v-btn
          v-if="
            formModel.status != 1 &&
            ((permissionCan('create') && !formModel.id) ||
              permissionCan('update'))
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalFormAndPreview"
        >
          Előnézet
        </v-btn>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { PERMISSION_TO } from "./News";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import SiteService from "@/core/services/site.service.js";
//import PreviewURLComponent from "@/view/components/PreviewURLComponent";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";
//import Treeselect from "@riophae/vue-treeselect";
//import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const TRANSLATED_ATRIBUTES = ["name", "slug", "text", "intro"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "author",
  "seoTitle",
  "seoDescription",
  "featured_image",
  "card_image",
  "gallery",

  "og_title",
  "og_description",
  "og_url",
  "og_site_name",
  "og_image",
];

export const INITIAL_CUSTOM_FIELDS = {
  og_type: "",
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  tags: [],
  related_news: [],
  translations: {},
  categories: [],
  custom_fields: INITIAL_CUSTOM_FIELDS,
});

export default {
  name: "NewsForm",
  props: [
    "modalData",
    "permissions",
    "statuses",
    "endPoint",
    "newsCategoryCollection",
  ],
  components: {
    CustomFieldComponent,
    //PreviewURLComponent,
    SnackBarInfoComponent,
  },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      date_picker_created_at: false,
      created_at_date: null,
      time_picker_created_at: false,
      created_at_time: null,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],

      editorConfigIntro: {
        htmlEncodeOutput: false,
        entities: false,
        removeButtons: "Underline,JustifyCenter",
        toolbar: [
          ["Source"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
        ],
      },

      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        forcePasteAsPlainText: true,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      editorConfig2: {
        htmlEncodeOutput: false,
        entities: false,
        forcePasteAsPlainText: true,
        extraAllowedContent: "iframe[*]",
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          // "?type=Images&maxWidth=706",
          "?type=Images",
      },
      tagCollection: [],
      og_types: ["website", "article"],
      activeSiteId: SiteService.getActiveSiteId(),

      snackbar: false,
      text: "",
      timeout: 3000,
    };
  },
  computed: {
    ...mapGetters(["newsCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.POST").toLowerCase();
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
    computedTagCollection() {
      let collection = [];
      this.tagCollection.forEach((item) => {
        if (item.locale == this.selectedLocale.lang) {
          collection.push(item.name);
        }
      });
      return collection;
    },
    computedRelatedNewsCollection() {
      let collection = [];
      this.newsCollection.forEach((item) => {
        if (item.id == this.formModel.id) {
          return;
        }
        if (item.translations[this.selectedLocale.lang]) {
          collection.push({
            id: item.id,
            label: item.translations[this.selectedLocale.lang].name,
          });
        }
      });
      return collection;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.snackbar = false;
        this.warningText = "";
        this.timeout = 3000;
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              if (!data.custom_fields)
                data.custom_fields = INITIAL_CUSTOM_FIELDS;

              this.formModel = Object.assign({}, data);

              this.setTranslatedAttributes();

              this.created_at_date = new Date(
                Date.parse(data.created_at) -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10);
              this.created_at_time = this.getTimeFormat(
                new Date(Date.parse(data.created_at))
              );
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          this.created_at_date = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.created_at_time = this.getTimeFormat(new Date());
          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    handleSaveModalFormAndPreview() {
      this.handleSaveModalForm(true);
    },
    getPreviewUrl(model_id) {
      this.loader = true;
      //let preview_endpoint = "news/admin/" + SiteService.getActiveSiteId() + "/news-preview/";
      let preview_endpoint = "news/admin/news-preview";
      //let site_url = process.env["VUE_APP_PAGE_BY_ID_" + SiteService.getActiveSiteId() + "_URL"];
      let data = {
        site_url:
          process.env[
            "VUE_APP_PAGE_BY_ID_" + SiteService.getActiveSiteId() + "_URL"
          ],
        locale: this.selectedLocale.lang,
      };
      ApiService.post(preview_endpoint + "/" + model_id, data)
        .then((response) => {
          console.log(response);
          window.open(response.data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    handleSaveModalForm(previewURL = null) {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();
      if (!model.categories) model.categories = [];

      model.created_at =
        this.created_at_date + " " + this.created_at_time + ":00";

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              setTimeout(() => {}, 0);
              this.$emit("saveModalForm", model.id);
              /*if (previewURL && previewURL != "") {
                window.open(previewURL, "_blank");
              }*/
              if (previewURL && previewURL != "") {
                this.getPreviewUrl(model.id);
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              setTimeout(() => {}, 0);
              this.$emit("saveModalForm", data.id);
              if (previewURL && previewURL != "") {
                window.open(previewURL, "_blank");
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
    fetchTag() {
      this.loader = true;
      ApiService.get("news/admin/" + SiteService.getActiveSiteId() + "/tag")
        .then(({ data }) => {
          this.tagCollection = data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
    deleteChipComboBox(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },

    deleteChipAutocomplete(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
    getTimeFormat(date) {
      let hours = String(date.getHours()).padStart(2, "0");
      let minutes = String(date.getMinutes()).padStart(2, "0");
      return hours + ":" + minutes;
    },

    handleChangeName() {
      let seoTitle = "";
      seoTitle += this.formModel.translations[this.selectedLocale.lang].name;
      this.handleChangeCustomFieldValue("seoTitle", seoTitle);
    },

    handleGenerateSEOText() {
      if (
        !this.formModel.translations[this.selectedLocale.lang].custom_fields
          .seoDescription ||
        this.formModel.translations[this.selectedLocale.lang].custom_fields
          .seoDescription.length == 0
      ) {
        this.handleChangeCustomFieldValue(
          "seoDescription",
          this.formModel.translations[this.selectedLocale.lang].text
            .replace(/(<([^>]+)>)/gi, "")
            .slice(0, 160)
        );
      }
    },

    handleImportMedia(type) {
      let formModel = this.formModel;
      this.formModel = null;

      let fromImportIndex = 0;

      if (this.languages[fromImportIndex].lang == this.selectedLocale.lang) {
        fromImportIndex = 1;
      }

      formModel.translations[this.selectedLocale.lang].custom_fields[type] = [
        ...formModel.translations[this.languages[fromImportIndex].lang]
          .custom_fields[type],
      ];

      this.formModel = formModel;
    },

    canImportMedia(type) {
      let canImport = false;

      let fromImportIndex = 0;

      if (this.languages[fromImportIndex].lang == this.selectedLocale.lang) {
        fromImportIndex = 1;
      }

      if (
        this.formModel.translations[this.languages[fromImportIndex].lang] &&
        this.formModel.translations[this.languages[fromImportIndex].lang]
          .custom_fields[type] &&
        this.formModel.translations[this.languages[fromImportIndex].lang]
          .custom_fields[type].length > 0
      ) {
        if (
          this.formModel &&
          this.formModel.translations &&
          this.formModel.translations[this.selectedLocale.lang] &&
          (!this.formModel.translations[this.selectedLocale.lang].custom_fields[
            type
          ] ||
            (this.formModel.translations[this.selectedLocale.lang]
              .custom_fields[type] &&
              this.formModel.translations[this.selectedLocale.lang]
                .custom_fields[type].length == 0))
        ) {
          canImport = true;
        }
      }

      // return canImport && this.permissionCan("create");
      return canImport;
    },

    setLimitRelatedNews(selectedIds) {
      if (selectedIds.length > 4) {
        this.formModel.related_news = this.formModel.related_news.slice(0, 4);
      }
    },

    handleSelectCategory() {
      this.formModel.categories = this.formModel.categories.slice(0, 1);
    },
  },

  mounted() {
    this.fetchTag();
    this.setTranslatedAttributes();
  },
};
</script>
